import React from 'react';
import { Box, Link, Typography } from '@mui/material';

function Footer() {
    return (
        <Box sx={{ mt: 6, mb: 2, textAlign: 'center' }}>
            <Typography variant="body2" color="gray">
                <Link color="inherit" href="https://doc.oct-app.com/docs/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </Link>
                {' | '}
                <Link color="inherit" href="https://doc.oct-app.com/legal/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    Terms of Use
                </Link>
            </Typography>
        </Box>
    );
}

export default Footer;
