import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './App.css';
import PhoneNumberForm from "./features/phone";
import {Box} from "@mui/material";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./features/footer";
import Spacer from "./features/spacer";

function App() {
  return (
      <Box className="App" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <ToastContainer theme="dark"/>
        <AppBar position="static">
                <Toolbar variant="dense" sx={{backgroundColor: 'primary.main'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography
                            fontWeight="bold"
                            variant="h5"
                            component="div"
                            marginLeft={1}
                            sx={{flexGrow: 1}}
                        >
                            Support Communication
                        </Typography>
                    </Box>
                </Toolbar>
        </AppBar>
          <Spacer />
        <Box margin="auto"
             display="flex"
             width={300}
             padding={2}
             sx={{backgroundColor: 'rgb(243,243,243)',borderRadius: '12px'}}>

            <Box justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"}>
                <Typography padding={1} fontSize={24} fontWeight={"bold"} sx={{textTransform: 'uppercase'}}>
                    Opt-In number
                </Typography>
                <Typography padding={1} fontSize={13}>
                    By submitting your phone number, you consent to receive SMS from Support Communication. Messaging and Data rates may apply. You can opt out at any time.
                </Typography>
                <PhoneNumberForm />
            </Box>
        </Box>
          <Spacer />
        <Footer/>
    </Box>

  );
}

export default App;
